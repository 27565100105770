



















import { LocaleChangerDropCpt } from '@/i18n';
import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import AuthStatusCpt from './AuthStatusCpt.vue';

@Component({
  components: {
    AuthStatusCpt,
    LocaleChangerDropCpt: () => Promise.resolve(LocaleChangerDropCpt),
  },
})
export default class HeaderCpt extends AppVue {
  get routeName() {
    return _.startCase(this.$route.name);
  }

  get showReporterOrg() {
    return this.isReporter && this.settings.currentReporterOrg && !this.$route.meta?.hideReporterOrg;
  }
}
